.roles-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 10px;
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #5f0dd1;
      color: #ffffff;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.08);
      text-align: center;
      transition: background-color 0.3s ease, transform 0.3s ease;
      padding: 10px; // Adjust the padding value here

      &:hover {
        background-color: #6f27d3;
        transform: scale(1.05);
      }
    }
  }
