
// https://m2.material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=673AB7&primary.color=212121
$background-color: #131313;
$container-background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-body {
  .align-center {
    text-align: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.big-downward-arrow {
  margin-top: 5rem;
}

.white {
  color: white;
}

.black {
  color: black;
}

.no-underline {
  text-decoration: none;
}

.white-background {
  background-color: white;
}

.grey-background {
  background-color: #f5f5f5;
}

.round-container {
  border-radius: 10px;
  padding: 2rem 2rem;
  margin: 1rem;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gradient {
  position: absolute;
  left: 5%;
  --size: 1000px;
  --speed: 50s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);

  width: var(--size);
  height: var(--size);
  filter: blur(calc(var(--size) / 5));
  background-image: linear-gradient(hsl(158, 82, 57, 85%), hsl(252, 82, 57));
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

@media (min-width: 720px) {
  .gradient {
    --size: 800px;
  }
}

.app-body {
  * {
    transition: all 0.25s ease-out;
  }
  background-color: #222;
  position: absolute;
  top: 40;
  inset: 0;
  display: flex;
  place-content: center;
  align-items: center;
  overflow: hidden;
}
