.centerItems {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; // This will ensure it takes up at least the full height of the viewport
}

.breakWordWrap {
  word-break: break-word;
}

.contactImage {
  max-width: 250px;
  height: auto;
  border-radius: 50%;
  min-width: 200px;
  min-height: 200px;
}
